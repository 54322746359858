import { useEffect, useRef, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { api, api_noreply } from './api.service';
import logo from './logo.png'
import Button from './components/button'
import React from 'react'

export default function PriceCalculator() {

  const [providers, setProviders] = useState([]);
  const [pages, setPages] = useState([]);
  const [selectedPages, setSelectedPages] = useState([])
  const [formValues, setFormValues] = useState({});
  const [submitted, setSubmitted] = useState(false)
  useEffect(() => {
    api(`${process.env.REACT_APP_API_URL}/public`).then(x => {
      setProviders(x.providers);
      setPages(x.pages);
    });
  }, [])

  const submit = () => {
    api_noreply(`${process.env.REACT_APP_API_URL}/public`, { formValues }).then(x => {
      setSubmitted(true)
    });
  }

  return <div className="min-h-screen bg-neutral-100 flex flex-col">

    <div className="flex-1 text-center">
      <div className="max-w-6xl mx-auto">
        <div className="px-10 pb-20 flex flex-col w-full">
          <div className="p-5 shadow-lg bg-white ">

            <img src={logo} className="w-16 h-16 mx-auto"></img>
            <div className="text-neutral-800 text-md font-semibold mb-1">Development Price Calculator</div>
            <div className="text-neutral-800 text-3xl font-semibold mb-3">Be clear on your Software Development Costs</div>
            <div className="text-neutral-500 text-md mt-3">The DevPrice Calculator allows you to judge how much you should be paying for software development, based on data collected from agencies and freelancers. Don’t allow developers to mislead and overcharge you, visit <a className="text-green-500 font-bold" target={'_blank'} href="https://verticode.co.uk">verticode.co.uk</a> to learn why we’re different.</div>
          </div>
          <div className="shadow-lg mt-5 bg-white">
            <div >
              <div className='text-neutral-800 text-2xl font-semibold py-3  bg-white'><div>Features</div>
                <div className="font-medium text-sm text-neutral-600">Click to add to calculation</div></div>
              <div className="h-96 overflow-y-auto bg-neutral-200 p-2 shadow-inner m-3 border borer-gray-200">
                <div className="grid grid-cols-3 gap-2 ">
                  {pages.map((x, i) => <div className=" cursor-pointer hover:bg-neutral-100 border border-dashed border-neutral-300 bg-white rounded-md shadow-lg p-3" onClick={() => { setPages(pages.filter((y, j) => j != i)); setSelectedPages(selectedPages.concat([x])) }}>
                    <div className="text-neutral-800 text-md font-semibold">{x.name}</div>
                    <div className="text-neutral-600 text-sm">{x.description}</div>
                  </div>)}
                </div>
              </div>

            </div>
          </div>
          <div className=" shadow-lg pb-10 px-5 bg-white pt-5">
            <div className="text-neutral-800 text-md font-semibold mb-1">Pricing Table</div>
            <div className="text-neutral-500 text-md">These prices represent an indication but cannot be guaranteed prior to scoping.</div>
            <div className="grid grid-cols-4 mt-10 text-neutral-700 border border-neutral-100" >
              <div className="bg-white">
                <div className="font-semibold text-lg text-neutral-800 ">Feature</div>
                <div className="my-1">Base Price</div>
                {selectedPages.map((x, i) => <div className="my-1">{x.name} <span className="cursor-pointer" onClick={() => { setSelectedPages(selectedPages.filter((y, j) => j != i)); setPages(pages.concat([x])) }}>❌</span></div>)}

                <div className="font-bold border-t border-neutral-200 mt-3 text-xl p-2">Total</div>
              </div>
              {providers.map((x, i) => <div className={`border-l border-neutral-200 ${i == 2 ? 'bg-green-50' : 'bg-neutral-50'}`}>
                <div className="font-semibold text-lg text-neutral-800 ">{x.name}</div>
                <div className="my-1">£{x.basePrice}</div>
                {selectedPages.map(y => <div className="my-1">£{y.prices ? y.prices[x.name] : '-'}</div>)}

                <div className="border-t border-neutral-200 mt-3 font-semibold text-xl p-2">£{selectedPages.reduce((acc, curr) => acc += curr.prices ? +curr.prices[x.name] : 0, 0) + x.basePrice}</div>
              </div>)}
            </div>
          </div>
          <div className=" shadow-lg pb-10 px-5 bg-white pt-5 mt-10">
            <div className="text-neutral-800 text-md font-semibold mb-1">Contact us to Find Out More</div>
            <div className="text-neutral-500 text-md">Get in touch if you want a precise quote, have any questions, or want to explore your project or app idea further.</div>
            
            {submitted && <div className="text-neutral-700 text-lg my-3">Your message has been sent</div>}
            {!submitted && <div className="py-10 px-6 sm:px-10 lg:col-span-2 xl:p-12">
              <form onSubmit={(e) => { e.preventDefault(); submit() }} method="POST" className="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8 text-left">
                <div>
                  <label htmlFor="name" className="block text-sm font-medium text-neutral-900">
                    Name
                  </label>
                  <div className="mt-1">
                    <input
                      required
                      id="name"
                      name="name"
                      value={formValues.name}
                      onChange={(e) => setFormValues({ ...formValues, name: e.target.value })}
                      type="text"
                      className="block w-full rounded-md border-neutral-300 py-3 px-4 text-neutral-900 shadow-sm focus:border-green-500 focus:ring-green-500"
                    />
                  </div>
                </div>

                <div>
                  <label htmlFor="email" className="block text-sm font-medium text-neutral-900">
                    Email
                  </label>
                  <div className="mt-1">
                    <input
                      required
                      id="email"
                      name="email"
                      type="email"
                      value={formValues.email}
                      onChange={(e) => setFormValues({ ...formValues, email: e.target.value })}
                      className="block w-full rounded-md border-neutral-300 py-3 px-4 text-neutral-900 shadow-sm focus:border-green-500 focus:ring-green-500"
                    />
                  </div>
                </div>

                <div className="sm:col-span-2">
                  <label htmlFor="subject" className="block text-sm font-medium text-neutral-900">
                    Subject
                  </label>
                  <div className="mt-1">
                    <input
                      id="subject"
                      name="subject"
                      type="text"
                      value={formValues.subject}
                      onChange={(e) => setFormValues({ ...formValues, subject: e.target.value })}
                      className="block w-full rounded-md border-neutral-300 py-3 px-4 text-neutral-900 shadow-sm focus:border-green-500 focus:ring-green-500"
                    />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <div className="flex justify-between">
                    <label htmlFor="message" className="block text-sm font-medium text-neutral-900">
                      Message
                    </label>
                  </div>
                  <div className="mt-1">
                    <textarea
                      id="message"
                      name="message"
                      rows={4}
                      className="block w-full rounded-md border-neutral-300 py-3 px-4 text-neutral-900 shadow-sm focus:border-green-500 focus:ring-green-500"
                      defaultValue={''}
                      value={formValues.message}
                      onChange={(e) => setFormValues({ ...formValues, message: e.target.value })}
                    />
                  </div>
                </div>
                <div className="sm:col-span-2 sm:flex sm:justify-end">
                  <button
                    type="submit"
                    className="mt-2 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-green-500 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:w-auto"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>}
          </div>
        </div>
      </div>
    </div>
  </div >
}
