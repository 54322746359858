import { api, api_delete } from './api.service'
import { Fragment, useEffect, useRef, useState } from 'react'
import { Transition, Dialog } from '@headlessui/react'
import Table from './components/table';
import Confirm from './components/confirm';

const objectSingle = "page";
const objectMulti = "pages";

export default function AdminPages() {

  const [data, setData] = useState([]);
  const [edit, setEdit] = useState();
  const [providers, setProviders] = useState();
  const [deleteId, setDeleteId] = useState();
  const deleteConfirm = () => {
    api_delete(`${process.env.REACT_APP_API_URL}/admin/${objectSingle}/${deleteId}`).then(x => {
      api(`${process.env.REACT_APP_API_URL}/admin/${objectMulti}`).then(x => {
        setData(x);
        setDeleteId(null)
      });
    });
  }

  const submit = (values) => {
    api(`${process.env.REACT_APP_API_URL}/admin/${objectSingle}`, { [objectSingle]: values }).then(x => {
      api(`${process.env.REACT_APP_API_URL}/admin/${objectMulti}`).then(x => {
        setData(x);
      });
    });
  }
  useEffect(() => {
    api(`${process.env.REACT_APP_API_URL}/admin/${objectMulti}`).then(x => {
      setData(x);
    });
    api(`${process.env.REACT_APP_API_URL}/public`).then(x => {
      setProviders(x.providers);
    });
  }, [])

  return (
    <>
      <Confirm open={!!deleteId} cancel={() => setDeleteId(false)} confirm={() => deleteConfirm(deleteId)}></Confirm>
      {edit && <EditModal providers={providers} close={() => setEdit(null)} submit={submit} edit={edit}></EditModal>}
      <div>
        <div className="flex px-4 sm:px-6 lg:px-8 max-w-6xl mx-auto">
          <div>
            <h2 className="capitalize flex-1 mx-auto text-lg font-medium leading-6 text-zinc-900 ">
              {objectMulti}
            </h2>
            <p className="text-sm text-neutral-500">These are the different locations in your organisation. These can be linked to configurations to keep inductions separate between sites. Create a general location if you want a configuration to apply across multiple sites.</p>

          </div>
          <div className="w-96">

            <button
              onClick={() => setEdit({})}
              type="button"
              className="float-right capitalize inline-flex items-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
            >
              Add {objectSingle}
            </button>

          </div>



        </div>


        <div className="block">
          <div className="mx-auto max-w-6xl px-4 sm:px-6 lg:px-8">
            <div className="mt-2 flex flex-col">
              <Table data={data.map(x => {
                return {
                  ...x, edit: <span className="cursor-pointer underline" onClick={() => setEdit(x)}>Edit</span>,
                  deleteCell: <span className="cursor-pointer underline" onClick={() => setDeleteId(x._id)}>Delete</span>
                }
              })} columns={[
                {
                  Header: 'Name',
                  accessor: 'name',
                },
                {
                  Header: 'Edit',
                  accessor: 'edit',
                },
                {
                  Header: 'Delete',
                  accessor: 'deleteCell',
                },
              ]}></Table>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const EditModal = ({ close, submit, edit, providers }) => {
  const [values, setValues] = useState({ prices: {} })

  useEffect(() => {
    if (edit) {
      if (!edit.prices) edit.prices = {}
      setValues(edit)
    }
  }, [])

  return (
    <Transition.Root show={true} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={close}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-neutral-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-visible rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-5xl sm:p-6">
                <div className="w-full">
                  <h1 className="capitalize text-lg font-semibold text-neutral-900 text-center">
                    Manage {objectSingle}
                  </h1>
                  <div className="py-10 px-4 sm:px-6">
                    <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">

                      <div className="sm:col-span-6">
                        <div>
                          <label className="block text-sm font-medium text-neutral-700 mt-2">
                            Name
                          </label>
                          <input
                            value={values.name}
                            onChange={(e) => setValues({ ...values, name: e.target.value })}
                            type="text"
                            className="block w-full min-w-0 flex-1 rounded-md border-neutral-300 focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                          />
                        </div>
                      </div>
                      <div className="sm:col-span-6">
                        <div>
                          <label className="block text-sm font-medium text-neutral-700 mt-2">
                            Description
                          </label>
                          <input
                            value={values.description}
                            onChange={(e) => setValues({ ...values, description: e.target.value })}
                            type="text"
                            className="block w-full min-w-0 flex-1 rounded-md border-neutral-300 focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                          />
                        </div>
                      </div>
                      {providers.map(x => <div className="sm:col-span-6">
                        <div>
                          <label className="block text-sm font-medium text-neutral-700 mt-2">
                            {x.name} Price (£)
                          </label>
                          <input
                            value={values.prices[x.name]}
                            onChange={(e) => setValues({ ...values, prices: { ...values.prices, [x.name]: e.target.value } })}
                            type="number"
                            className="block w-full min-w-0 flex-1 rounded-md border-neutral-300 focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                          />
                        </div>
                      </div>)}

                    </div>
                    <button
                      onClick={() => close()}
                      type="button"
                      className="mr-5 rounded-md mt-5 border border-transparent bg-white-600 py-2 px-4 text-sm font-medium text-blue-800 shadow-sm hover:bg-neutral-50 focus:outline-none focus:ring-2 focus:ring-neutral-50 focus:ring-offset-2"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={() => { submit(values); close() }}
                      type="button"
                      className=" rounded-md mt-5 border border-transparent bg-blue-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
