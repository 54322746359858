import axios from 'axios';
export const api = (endpoint, data) => {
    if (data) {
        return fetch(endpoint, {
            method: 'POST',
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json'
            }),
            body: JSON.stringify(data)
        }).then(res => { if (res.status === 401) window.location.replace('/login'); return res.json() })
    }
    else {
        return fetch(endpoint, {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            })
        }).then(res => { if (res.status === 401) window.location.replace('/login'); return res.json() })
    }
}

export const api_noreply = (endpoint, data) => {
    if (data) {
        return fetch(endpoint, {
            method: 'POST',
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json'
            }),
            body: JSON.stringify(data)
        }).then(res => { if (res.status === 401) window.location.replace('/login'); return res })
    }
    else {
        return fetch(endpoint, {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            })
        }).then(res => { if (res.status === 401) window.location.replace('/login'); return res })
    }
}

export const api_file = async (endpoint, data) => {
    const formData = new FormData();

    for (let f of data) {
        formData.append(f.name, f);
    }

    return await axios.post(endpoint, formData, {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

}

export const api_file_no_auth = async (endpoint, data) => {
    const formData = new FormData();

    for (let f of data) {
        formData.append(f.name, f);
    }

    return await axios.post(endpoint, formData, {
        headers: {
          "content-type": "multipart/form-data"
        },
      });

}

export const api_update = (endpoint, data) => {
    return fetch(endpoint, {
        method: 'PUT',
        headers: new Headers({
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json'
        }),
        body: JSON.stringify(data)
    }).then(res => { if (res.status === 401) window.location.replace('/login'); return res.json() })
}

export const api_delete = (endpoint) => {
    return fetch(endpoint, {
        method: 'DELETE',
        headers: new Headers({
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        })
    }).then(res => { if (res.status === 401) window.location.replace('/login'); return res })
}