import { Routes, Route } from "react-router-dom";
import './App.css';
import Login from "./login";
import Logout from "./logout";
import AdminLayout from "./admin-layout";
import AdminUsers from "./admin-users";
import PriceCalculator from "./price-calculator";
import AdminProviders from "./admin-providers";
import AdminPages from "./admin-pages";

export default function App() {
  return (
    <Routes>
      <Route path="/" element={<PriceCalculator />} />
      <Route path="/login" element={<Login />} />
      <Route path="/logout" element={<Logout />} />

      <Route path='/admin' element={<AdminLayout />}>
        <Route path="/admin" element={<AdminPages />} />
        <Route path="/admin/providers" element={<AdminProviders />} />
        <Route path="/admin/users" element={<AdminUsers />} />
      </Route>
    </Routes>
  )
}
